import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class UserEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to users', path: '/users'},
            user: null,
            roleOptions: [
                {
                    key: 'admin', label: 'Admin'
                },
                {
                    key: 'authority', label: 'Authority'
                },
                {
                    key: 'brand', label: 'Brand'
                }
            ],
            authorityTypeOptions: [
                {
                    key: 'gacc', label: 'GACC'
                },
                {
                    key: 'customs', label: 'Customs'
                },
                {
                    key: 'psb', label: 'PSB'
                },
                {
                    key: 'samr', label: 'SAMR'
                },
                {
                    key: 'msa', label: 'MSA'
                },
                {
                    key: 'others', label: 'Others'
                }
            ],
            companyOptions: [],
            passUpdate: false
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        Promise.all([
            // API.getUser(this.props.match.params.userId).then(user => this.setState({user: user})),
            API.getUser(this.props.match.params.userId).then(user => {
                user.password = '******'
                this.setState({user: user})
            }),
            API.getCompanies().then(companies => {
                var options = []
                options.push({id: '', label: ''})
                companies.forEach(company => {
                    let option = {}
                    if (company.name.en) {
                        option.key = company.id
                        option.label = company.name.en
                    } else {
                        option.key = company.id
                        option.label = company.name.zh
                    }
                    options.push(option)
                })
                options.push({id: '', label: 'Others'})
                this.setState({
                    companyOptions: options
                })
            })
        ]).then(() => {
            setTimeout(() => {
                this.setState({loading: false})
            }, 100)
        })
    }

    validateUser(id) {
        this.setState({loading: true})

        const status = {
            status: 'validated',
            validationDate: new Date()
        }
        API.update('users', id, status).then(() => {
            API.sendEmailToUser(id).then(() => {
                toast.success('User has been validated')
                window.location.reload(true)
                // this.props.history.push('/users')
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    updateField(e, key) {
        this.setState({
            user: {
                ...this.state.user,
                [key]: e.target.value
            }
        })

        if (key === 'password') {
            this.setState({
                passUpdate: true
            })
        }
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (this.state.passUpdate === false) {
            delete fields.password
        }

        if (!this.state.user.name || !this.state.user.email) {
            toast.error('Username and Email can not be empty')
            this.setState({loading: false})
        } else {
            fields.name = fields.name.trim()

            if (this.state.user.companyName) {
                const newCompany = {
                    name: {
                        en: this.state.user.companyName,
                        zh: ''
                    },
                    agentType: 'other-agent'
                }

                // If user choose 'Others' in select field, it will create a new company
                API.create('companies', newCompany).then((result) => {
                    const updateCompanyId = {
                        company: result.id
                    }

                    fields.company = result.id

                    // API.update('companies', result.id, updateCompanyId).then(() => {
                        API.update('users', this.props.match.params.userId, fields).then(() => {
                            toast.success('User has been updated')
                            window.location.reload(true)
                        }).catch(() => {
                            toast.error('An unexpected error has occured, please try again')
                            this.setState({loading: false})
                        })
                    // }).catch(() => {
                    //     toast.error('An unexpected error has occured, please try again')
                    //     this.setState({loading: false})
                    // })
                }).catch(() => {
                    toast.error('An unexpected error has occured, please try again')
                    this.setState({loading: false})
                })
            } else {
                API.update('users', this.props.match.params.userId, fields).then(() => {
                    toast.success('User has been updated')
                    window.location.reload(true)
                }).catch(() => {
                    toast.error('An unexpected error has occured, please try again')
                    this.setState({loading: false})
                })
            }
        }
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this user?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteUser(this.props.match.params.userId).then(() => {
            toast.success('User was successfully deleted')
            this.props.history.push('/users')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit user'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => this.onSubmit(e, () => this.saveEntity(this.state.user))}>
                        <div className="actions clearfix">
                            {
                                this.state.user.status === 'waiting-for-validation' &&
                                <div className="buttons clearfix" style={{float: "left"}}>
                                    <button className="green"
                                            onClick={(e) => this.onSubmit(e, () => this.validateUser(this.state.user.id))}>
                                        Validate user account
                                    </button>
                                </div>
                            }

                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/users'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block">
                            <div className="fields clearfix">
                                <div className="fields clearfix">
                                    <h3>Edit information</h3>
                                    <div className="field" key="name">
                                        <label>User name</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'name')}
                                               value={this.state.user.name}/>
                                    </div>
                                    <div className="field" key="role">
                                        <label>Role</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'role')}
                                            value={this.state.user.role}>
                                            {
                                                this.state.roleOptions.map(option =>
                                                    <option
                                                        value={option.key}>{option.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.user.role === "authority" &&
                                        <div className="field" key="authorityType">
                                            <label>Authority type</label>
                                            <select
                                                onChange={(e) => this.updateField(e, 'authorityType')}
                                                value={this.state.user.authorityType}>
                                                {
                                                    this.state.authorityTypeOptions.map(option =>
                                                        <option
                                                            value={option.key}>{option.label}</option>)
                                                }
                                            </select>
                                        </div>
                                    }
                                    <div className="field" key="email">
                                        <label>Email</label>
                                        <input type="email"
                                               onChange={(e) => this.updateField(e, 'email')}
                                               value={this.state.user.email}/>
                                    </div>
                                    <div className="field" key="phone">
                                        <label>Phone number</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'phone')}
                                               value={this.state.user.phone}/>
                                    </div>
                                    <div className="field" key="company">
                                        <label>Company</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'company')}
                                            value={this.state.user.company}>
                                            {
                                                this.state.companyOptions.map(option =>
                                                    <option
                                                        value={option.key}>{option.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.user.company === "Others" &&
                                        <div className="field" key="companyName">
                                            <label>Company name</label>
                                            <input type="text"
                                                   onChange={(e) => this.updateField(e, 'companyName')}
                                                   value={this.state.user.companyName}/>
                                        </div>
                                    }
                                    <div className="field" key="password">
                                        <label>Password</label>
                                        <input type="password"
                                               onChange={(e) => this.updateField(e, 'password')}
                                               value={this.state.user.password}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="actions clearfix below-form">
                        <button onClick={() => this.deleteEntity()} className="red">Delete user</button>
                    </div>
                </Content>
            </>
    }
}

export default UserEdit

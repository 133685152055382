import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class CategoryNew extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            breadcrumb: {title: 'Back to Categories', path: '/categories'},
            fields: [
                {
                    label: 'Add information',
                    fields: {
                        nameEn: {
                            label: 'Category name in English',
                            type: 'text'
                        },
                        nameZh: {
                            label: 'Category name in Chinese',
                            type: 'text'
                        },
                    }
                }
            ],
            category: null,
            entity: {
                nameEn: null,
                nameZh: null
            }
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {

    }

    saveEntity(fields) {
        this.setState({ loading: true })

        if (!fields.nameEn && !fields.nameZh) {
            toast.error('Category name can not be empty')
            this.setState({loading: false})
        } else {
            fields.name = {}
            fields.name.en = fields.nameEn
            fields.name.zh = fields.nameZh
            delete fields.nameEn
            delete fields.nameZh

            API.create('categories', fields).then(() => {
                toast.success('Category has been created')
                this.props.history.push('/categories')
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({ loading: false })
            })
        }
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add category'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <Form fields={this.state.fields}
                          entity={this.state.entity}
                          cancel={() => this.props.history.push('/categories')}
                          save={this.saveEntity}/>
                </Content>
            </>
    }
}

export default CategoryNew;

import {React} from 'react'
import API from '../../services/api'

function PreviewImage(props) {
    const removeImageProp = props.removeImage
    const previewProp = props.preview

    return (
        <div className={"image-wrap" + (props.thinner ? ' thinner' : '')} key={props.id}>
            {props.label !== '' && <label>{props.label}</label>}
            <div className="image-icon-wrap">
                <a href={API.apiURL + previewProp} target="_blank">
                    <img alt="preview" src={API.apiURL + previewProp}/>
                </a>
                <p>{props.name}</p>
                <i onClick={() => removeImageProp()} className="icon-trash"></i>
            </div>
        </div>
    )
}

export default PreviewImage
import React from 'react'
import EditorConvertToHTML from '../../layout/editor.jsx'

class CounterfeitForm extends React.Component {
    constructor(props) {
        super(props)
        const entity = this.props.entity

        this.state = {
            entity
        }
    }

    componentDidMount() {
        const entity = this.props.entity

        this.setState({
            entity
        })
    }

    addKeyFeature = () => {
        const counterfeit = this.state.entity.counterfeit

        const newKeyFeature = {
            keyFeatureName: {
                en: '',
                zh: ''
            },
            description: {
                en: '',
                zh: ''
            },
        }

        const newKeyFeatures = [...counterfeit, newKeyFeature]

        this.setState({
            entity: {
                ...this.state.entity,
                counterfeit: newKeyFeatures
            }
        })
    }

    removeKeyFeature = index => {
        var r = window.confirm("Are you sure you want to delete this key feature?");
        if (r === false) return

        let counterfeit = this.state.entity.counterfeit

        counterfeit.splice(index, 1)

        this.setState(prevState => ({
            ...prevState,
            counterfeit
        }))
    }

    handleChange = (e, type, key, index = 0, editor = false) => {
        var newValue = e
        let {entity} = this.state

        if (type === 'description') {
            var value = newValue.replace(/text-align:none/g, 'text-align:center')
            value = value.replace(/<p style="text-align:left;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:center;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:right;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:none;"><\/p>/g, '')
            value = value.replace(/<p><\/p>/g, '')
            newValue = value + '<p></p>'
        }

        if (editor) {
            entity.counterfeit[index][type][key] = newValue
        } else {
            entity.counterfeit[index][key] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            entity
        }))
    }

    render() {
        const {entity} = this.state

        return <>
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.save({...this.state})
            }} className="custom-form">
                <div className="actions clearfix">
                    <div className="buttons clearfix">
                        <button className="grey" onClick={(e) => {
                            e.preventDefault();
                            this.props.cancel()
                        }}>Cancel
                        </button>
                        <button className="blue">Save page</button>
                    </div>
                </div>

                <div className="block above">
                    <div className="fields container clearfix">
                        <h3>Counterfeit key indicators</h3>

                        {
                            Object.keys(entity.counterfeit).map(index => {
                                return (
                                    <div className="fields container clearfix">
                                        <div>
                                            <p style={{
                                                textDecoration: 'underline'
                                            }}>{parseInt(index) + 1}. Key feature {parseInt(index) + 1}
                                            </p>
                                            <label style={{
                                                textDecorationLine: 'underline',
                                                color: '#2196F3',
                                                position: 'absolute',
                                                right: '32px',
                                                cursor: 'pointer'
                                            }}
                                                   onClick={() => {
                                                       this.removeKeyFeature(index)
                                                   }}
                                            >Delete
                                            </label>
                                        </div>

                                        <div className="field" key="keyFeatureNameEn">
                                            <label>Key feature name in English</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, 'keyFeatureName', 'en', index, true)}
                                                   value={entity?.counterfeit[index].keyFeatureName?.en || ""}/>
                                        </div>
                                        <div className="field" key="keyFeatureNameZh">
                                            <label>Key feature name in Chinese</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, 'keyFeatureName', 'zh', index, true)}
                                                   value={entity?.counterfeit[index].keyFeatureName?.zh || ""}/>
                                        </div>

                                        <div className="long-field" key="descriptionEn">
                                            <label>Description text in English</label>
                                            <EditorConvertToHTML
                                                text={entity?.counterfeit[index].description?.en}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'en', index, true)}
                                            />
                                        </div>

                                        <div className="long-field" key="descriptionZh">
                                            <label>Description text in Chinese</label>
                                            <EditorConvertToHTML
                                                text={entity?.counterfeit[index].description?.zh}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'zh', index, true)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="buttons">
                            <button className="blue add clearfix"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.addKeyFeature()
                                    }}>
                                Add new key feature
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}

export default CounterfeitForm
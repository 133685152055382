import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from "../layout/file-handler/fileHandler"

class BrandNew extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Brand', path: '/brands'},
            companyOptions: [],
            categoryOptions: [],
            agentTypeOptions: [
                {
                    key: 'other-agent', label: 'Other agent'
                },
                {
                    key: 'shanghai-jingcui', label: 'Shanghai JingCui'
                }
            ],
            tempLogo: null,
            brand: {
                name: {
                    en: null,
                    zh: null
                },
                company: null,
                category: null,
                description: {
                    en: null,
                    zh: null
                },
                promoted: false,
                agentType: 'other-agent',
                agentName: null,
                agentContact: null,
                display: false
            }
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        Promise.all([
            API.getCompanies().then(companies => {
                var options = []
                options.push({key: '', label: '---Choose---'})
                companies.forEach(company => {
                    let option = {}
                    if (company.name.en) {
                        option.key = company.id
                        option.label = company.name.en
                    } else {
                        option.key = company.id
                        option.label = company.name.zh
                    }
                    options.push(option)
                })
                this.setState({
                    companyOptions: options,
                    brand: {
                        ...this.state.brand,
                        company: options[0].key
                    }
                })
            }),
            API.getCategories().then(categories => {
                var options = []
                options.push({key: '', label: '---Choose---'})
                categories.forEach(category => {
                    let option = {}
                    if (category.name.en) {
                        option.key = category.id
                        option.label = category.name.en
                    } else {
                        option.key = category.id
                        option.label = category.name.zh
                    }
                    options.push(option)
                })
                this.setState({
                    categoryOptions: options,
                    brand: {
                        ...this.state.brand,
                        category: options[0].key
                    }
                })
            })
        ]).then(() => {
            setTimeout(() => {
                this.setState({loading: false})
            }, 100)
        })
    }

    updateField(e, key) {
        this.setState({
            brand: {
                ...this.state.brand,
                [key]: e.target.value
            }
        })
    }

    updateBoolean(value, key) {
        this.setState({
            brand: {
                ...this.state.brand,
                [key]: value
            }
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (!this.state.brand.name.en && !this.state.brand.name.zh) {
            toast.error('Brand name can not be empty')
            this.setState({loading: false})
            return
        } else if (this.state.brand.company === '') {
            toast.error('Company can not be empty')
            this.setState({loading: false})
            return
        } else if (this.state.brand.category === '') {
            toast.error('Category can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.brand.logo) {
            toast.error('Brand logo can not be empty')
            this.setState({loading: false})
            return
        } else {
            if (this.state.brand.logo) {
                let logo = this.state.brand.logo
                logo.uri = logo.uri.replace('storage/brand-logo/', '')

                this.setState({
                    brand: {
                        ...this.state.brand,
                        logo: logo
                    }
                })
            }

            const newBrand = this.state.brand

            newBrand.display = true
            API.create('brands', newBrand).then((result) => {
                toast.success('Brand has been created')
                this.props.history.push('/brands')
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        }
    }

    // For complicated structure
    handleChange = (e, type, key, index = 0, editor = false) => {
        let newValue = e
        let {brand} = this.state

        brand[type][key] = newValue

        this.setState(previousState => ({
            ...previousState,
            brand
        }))
    }

    createCorrectUrl = (objectOfImage) => {
        let urlPrefix = 'storage/brand-logo/'
        var newObject = objectOfImage

        newObject.uri = urlPrefix + objectOfImage.uri

        return newObject
    }

    showImage = (file, fileObj, type, index) => {
        var brand = this.state.brand

        fileObj = this.createCorrectUrl(fileObj)
        brand.logo = fileObj

        this.setState(previousState => ({
            brand,
            tempLogo: brand.logo
        }))
    }

    removeImage = (type) => {
        const brand = {...this.state.brand}

        delete brand.logo

        this.setState(previousState => ({
            brand,
            tempLogo: null
        }))
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add brand'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.brand))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/brands'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameEn">
                                    <label>Brand name in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'en')}
                                           value={this.state.brand.name.en}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Brand name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh')}
                                           value={this.state.brand.name.zh}/>
                                </div>
                                <div className="field" key="company">
                                    <label>Company</label>
                                    <select
                                        onChange={(e) => this.updateField(e, 'company')}
                                        value={this.state.brand.company}>
                                        {
                                            this.state.companyOptions.map(option =>
                                                <option
                                                    value={option.key}>{option.label}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="field" key="category">
                                    <label>Category</label>
                                    <select
                                        onChange={(e) => this.updateField(e, 'category')}
                                        value={this.state.brand.category}>
                                        {
                                            this.state.categoryOptions.map(option =>
                                                <option
                                                    value={option.key}>{option.label}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="field" key="logo">
                                    <div className="file-handler-container">
                                        {this.state.tempLogo &&
                                        <div className="field" key="logo">
                                            <FileHandler
                                                image={this.state.tempLogo.uri}
                                                name={this.state.tempLogo.name}
                                                removeImage={(type) => this.removeImage(type)}
                                                type={'images'}
                                                label={'Brand logo'}
                                                fileType={'images'}
                                                path={'brand-logo'}
                                            />
                                        </div>
                                        }

                                        {!this.state.tempLogo &&
                                        <FileHandler
                                            showImage={this.showImage}
                                            type={'images'}
                                            label={'Brand logo'}
                                            fileType={'images'}
                                            path={'brand-logo'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>

                                <div className="long-field" key="descriptionEn">
                                    <label>Description text in English</label>
                                    {/*<input type="textarea"*/}
                                    {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'en')}*/}
                                    {/*       value={this.state.brand.description.en}/>*/}
                                    <textarea onChange={(e) => this.handleChange(e.target.value, 'description', 'en')}
                                              value={this.state.brand.description.en}/>
                                </div>

                                <div className="long-field" key="descriptionZh">
                                    <label>Description text in Chinese</label>
                                    {/*<input type="textarea"*/}
                                    {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'zh')}*/}
                                    {/*       value={this.state.brand.description.zh}/>*/}
                                    <textarea onChange={(e) => this.handleChange(e.target.value, 'description', 'zh')}
                                              value={this.state.brand.description.zh}/>
                                </div>

                                <div className="long-field" key="promoted" style={{display: 'flex'}}>
                                    <input type="checkbox"
                                           onChange={(e) => this.updateBoolean(!this.state.brand.promoted, 'promoted')}
                                           checked={this.state.brand.promoted === true ? true : false}
                                           value={this.state.brand.promoted}/>
                                    <p style={{margin: '2px'}}>Promoted brand on Mini-program</p>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Brand agent</h3>
                                <div className="field" key="agentType">
                                    <label>Agent type</label>
                                    <select
                                        onChange={(e) => this.updateField(e, 'agentType')}
                                        value={this.state.brand.agentType}>
                                        {
                                            this.state.agentTypeOptions.map(option =>
                                                <option
                                                    value={option.key}>{option.label}</option>)
                                        }
                                    </select>
                                </div>
                                {
                                    this.state.brand.agentType === "other-agent" &&
                                    <div>
                                        <div className="field" key="agentName">
                                            <label>Brand agent name</label>
                                            <input type="text"
                                                   onChange={(e) => this.updateField(e, 'agentName')}
                                                   value={this.state.brand.agentName}/>
                                        </div>
                                        <div className="field" key="agentContact">
                                            <label>Brand agent contact</label>
                                            <input type="text"
                                                   onChange={(e) => this.updateField(e, 'agentContact')}
                                                   value={this.state.brand.agentContact}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default BrandNew

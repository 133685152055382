import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'

class CategoryList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {},
            categories: {
                columns: [
                    {
                        label: 'Category name in English',
                        key: 'nameEn'
                    },
                    {
                        label: 'Category name in Chinese',
                        key: 'nameZh'
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/categories/' + id + '/edit')
                        }
                    }],
                entities: [],
                filters: [],
                actions: [{
                    classList: 'blue add',
                    label: 'New category',
                    onClick: () => this.props.history.push('/categories/new')
                }],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        categories: {
                            ...this.state.categories,
                            range: [0, this.state.categories.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.categories.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/categories/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getCategories('', this.state.categories.defaultRange).then(entities => {
            var categoryList = []
            entities.forEach(entity => {
                let categoryName = {
                    id: entity.id,
                    nameEn: entity.name.en,
                    nameZh: entity.name.zh
                }
                categoryList.push(categoryName)
            })
            this.setState({
                ...this.state,
                loading: false,
                categories: {...this.state.categories, entities: categoryList}
            })

            if (entities.length === this.state.categories.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    categories: {
                        ...this.state.categories,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getCategories('', range).then(entities => {
            var categoryList = []
            entities.forEach(entity => {
                let categoryName = {
                    id: entity.id,
                    nameEn: entity.name.en,
                    nameZh: entity.name.zh
                }
                categoryList.push(categoryName)
            })
            this.setState({
                ...this.state,
                loading: false,
                categories: {...this.state.categories, entities: categoryList}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    categories: {
                        ...this.state.categories,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    categories: {
                        ...this.state.categories,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Categories'}/>
                <Content>
                    <Table settings={this.state.categories}/>
                </Content>
            </>
    }
}

export default CategoryList

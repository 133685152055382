import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'

class ComplaintList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {},
            sort: ['updatedAt', 'DESC'],
            complaints: {
                columns: [
                    {
                        label: 'Complaint #',
                        key: 'complaintId'
                    },
                    {
                        label: 'Name',
                        key: 'name',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['complainantInformation.name', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'complainantInformation.name') {
                                this.setState({
                                    sort: ['complainantInformation.name', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'complainantInformation.name' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['complainantInformation.name', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'complainantInformation.name' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['complainantInformation.name', 'ASC']
                                })
                            }

                            this.loadEntities(this.state.complaints.range)
                        },
                    },
                    {
                        label: 'Mobile number',
                        key: 'phone'
                    },
                    {
                        label: 'Complaint date',
                        key: 'createdAt',
                        view: function (field) {
                            return moment(field).format('YYYY-MM-DD')
                        }
                    },
                    {
                        label: 'Environment',
                        key: 'environment'
                    },
                    {
                        label: 'Willing to be contacted',
                        key: 'willingToBeContacted'
                    },
                    {
                        content: 'See complaint',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/complaints/' + id)
                        }
                    }],
                entities: [],
                filters: [],
                actions: [],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        complaints: {
                            ...this.state.complaints,
                            range: [0, this.state.complaints.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.complaints.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/complaints/' + entity.id)
                // }
            }
        }
    }

    componentDidMount() {
        API.getComplaints(this.state.filters, this.state.sort, this.state.complaints.defaultRange).then(entities => {
            entities.forEach(entity => {
                entity.name = entity.complainantInformation.name
                entity.phone = entity.complainantInformation.phone
                entity.environment = entity.complaintEnvironment.environment

                if (entity.willingToBeContacted === true) {
                    entity.willingToBeContacted = 'Yes'
                } else {
                    entity.willingToBeContacted = 'No'
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                complaints: {...this.state.complaints, entities: entities}
            })

            if (entities.length === this.state.complaints.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    complaints: {
                        ...this.state.complaints,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getComplaints(this.state.filters, this.state.sort, range).then(entities => {
            entities.forEach(entity => {
                entity.name = entity.complainantInformation.name
                entity.phone = entity.complainantInformation.phone
                entity.environment = entity.complaintEnvironment.environment

                if (entity.willingToBeContacted === true) {
                    entity.willingToBeContacted = 'Yes'
                } else {
                    entity.willingToBeContacted = 'No'
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                complaints: {...this.state.complaints, entities: entities}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    complaints: {
                        ...this.state.complaints,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    complaints: {
                        ...this.state.complaints,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Complaints'}/>
                <Content>
                    <Table settings={this.state.complaints}/>
                </Content>
            </>
    }
}

export default ComplaintList

import React from 'react'

class HomepageForm extends React.Component {
    constructor(props) {
        super(props)
        const entity = this.props.entity

        this.state = {
            entity
        }
    }

    componentDidMount() {
        const entity = this.props.entity

        this.setState({
            entity
        })
    }

    updateField(e, key) {
        this.setState({
            entity: {
                ...this.state.entity,
                [key]: e.target.value
            }
        })
    }

    handleChange = (e, type, key, index = 0, editor = false) => {
        let newValue = e
        let {entity} = this.state

        if (editor) {
            entity.homepage[type][key] = newValue
        } else {
            newValue = newValue.target.value
            entity.homepage[type][index][key] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            entity
        }))
    }

    render() {
        const {entity} = this.state

        return <>
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.save({...this.state})
            }} className="custom-form">
                <div className="actions clearfix">
                    <div className="buttons clearfix">
                        <button className="grey" onClick={(e) => {
                            e.preventDefault();
                            this.props.cancel()
                        }}>Cancel
                        </button>
                        <button className="blue">Save page</button>
                    </div>
                </div>

                <div className="block above">
                    <div className="fields container clearfix">
                        <h3>Page information</h3>
                        <div className="field" key="pageName">
                            <label>Page name</label>
                            <input type="text"
                                   disabled
                                   onChange={(e) => this.updateField(e, 'pageName')}
                                   value={entity?.pageName || ""}/>
                        </div>
                        <div className="field" key="pageId">
                            <label>Page ID</label>
                            <input type="text"
                                   disabled
                                   onChange={(e) => this.updateField(e, 'pageId')}
                                   value={entity?.pageId || ""}/>
                        </div>
                    </div>

                    <div className="fields container clearfix">
                        <h3>Description</h3>
                        <div className="field" key="titleEn">
                            <label>English Title text</label>
                            <input type="text"
                                   required
                                   onChange={(e) => this.handleChange(e.target.value, 'title', 'en', 0, true)}
                                   value={entity?.homepage.title?.en || ""}/>
                        </div>
                        <div className="field" key="titleZh">
                            <label>Chinese Title text</label>
                            <input type="text"
                                   required
                                   onChange={(e) => this.handleChange(e.target.value, 'title', 'zh', 0, true)}
                                   value={entity?.homepage.title?.zh || ""}/>
                        </div>

                        <div className="long-field" key="descriptionEn">
                            <label>English Description text</label>
                            {/*<input type="textarea"*/}
                            {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'en', 0, true)}*/}
                            {/*       value={entity?.homepage.description?.en || ""}/>*/}
                            <textarea required
                                      onChange={(e) => this.handleChange(e.target.value, 'description', 'en', 0, true)}
                                      value={entity?.homepage.description?.en || ""}/>
                        </div>

                        <div className="long-field" key="descriptionZh">
                            <label>Chinese Description text</label>
                            {/*<input type="textarea"*/}
                            {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'zh', 0, true)}*/}
                            {/*       value={entity?.homepage.description?.zh || ""}/>*/}
                            <textarea required
                                      onChange={(e) => this.handleChange(e.target.value, 'description', 'zh', 0, true)}
                                      value={entity?.homepage.description?.zh || ""}/>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}

export default HomepageForm
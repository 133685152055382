import React from 'react'
import EditorConvertToHTML from '../../../layout/editor.jsx'
import API from "../../../../services/api"
import {toast} from "react-toastify"
import Loader from "../../../layout/loader"

class TrainingMaterials extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            product: {
                trainingMaterials: []
            }
        }

        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        if (this.props.match.params.productId) {
            API.getProduct(this.props.match.params.productId).then(product => {
                this.setState({loading: false, product: product})
            })
        }
    }

    addKeyFeature = () => {
        const trainingMaterials = this.state.product.trainingMaterials

        const newKeyFeature = {
            keyFeatureName: {
                en: '',
                zh: ''
            },
            description: {
                en: '',
                zh: ''
            },
        }

        const newKeyFeatures = [...trainingMaterials, newKeyFeature]

        this.setState({
            product: {
                ...this.state.product,
                trainingMaterials: newKeyFeatures
            }
        })
    }

    removeKeyFeature = index => {
        var r = window.confirm("Are you sure you want to delete this key feature?");
        if (r === false) return

        let trainingMaterials = this.state.product.trainingMaterials

        trainingMaterials.splice(index, 1)

        this.setState(prevState => ({
            ...prevState,
            trainingMaterials
        }))
    }

    handleChange = (e, type, key, index = 0, editor = false) => {
        var newValue = e
        let {product} = this.state

        if (type === 'description') {
            var value = newValue.replace(/text-align:none/g, 'text-align:center')
            value = value.replace(/<p style="text-align:left;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:center;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:right;"><\/p>/g, '')
            value = value.replace(/<p style="text-align:none;"><\/p>/g, '')
            value = value.replace(/<p><\/p>/g, '')
            newValue = value + '<p></p>'
        }

        if (editor) {
            product.trainingMaterials[index][type][key] = newValue
        } else {
            product.trainingMaterials[index][key] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            product
        }))
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (this.props.match.params.productId) {
            API.update('products', this.props.match.params.productId, fields).then((result) => {
                toast.success('Product has been updated')
                this.props.history.push(`/brands/${this.props.match.params.brandId}/products`)
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        } else {
            API.create('products', fields).then((result) => {
                toast.success('Product has been created')
                this.props.history.push(`/brands/${this.props.match.params.brandId}/products`)
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        }
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        const {product} = this.state

        if (this.state.loading)
            return <Loader/>
        return <>
            <form onSubmit={(e) => {
                this.onSubmit(e, () => this.saveEntity(this.state.product))
            }} className="custom-form">
                <div className="actions clearfix">
                    <div className="buttons clearfix">
                        <button className="grey"
                                onClick={(e) => this.onCancel(e, () => this.props.history.push(`/brands/${this.props.match.params.brandId}/products`))}>Cancel
                        </button>
                        <button className="blue">Save</button>
                    </div>
                </div>

                <div className="block above">
                    <div className="fields container clearfix">
                        <h3>Training materials</h3>

                        {
                            Object.keys(product?.trainingMaterials)?.map(index => {
                                return (
                                    <div className="fields container clearfix">
                                        <div>
                                            <p style={{
                                                textDecoration: 'underline'
                                            }}>{parseInt(index) + 1}. Key feature {parseInt(index) + 1}
                                            </p>
                                            <label style={{
                                                textDecorationLine: 'underline',
                                                color: '#2196F3',
                                                position: 'absolute',
                                                right: '32px',
                                                cursor: 'pointer'
                                            }}
                                                   onClick={() => {
                                                       this.removeKeyFeature(index)
                                                   }}
                                            >Delete
                                            </label>
                                        </div>

                                        <div className="field" key="keyFeatureNameEn">
                                            <label>Key feature name in English</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, 'keyFeatureName', 'en', index, true)}
                                                   value={product?.trainingMaterials[index].keyFeatureName?.en || ""}/>
                                        </div>
                                        <div className="field" key="keyFeatureNameZh">
                                            <label>Key feature name in Chinese</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, 'keyFeatureName', 'zh', index, true)}
                                                   value={product?.trainingMaterials[index].keyFeatureName?.zh || ""}/>
                                        </div>

                                        <div className="long-field" key="descriptionEn">
                                            <label>Description text in English</label>
                                            <EditorConvertToHTML
                                                text={product?.trainingMaterials[index].description?.en}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'en', index, true)}
                                            />
                                        </div>

                                        <div className="long-field" key="descriptionZh">
                                            <label>Description text in Chinese</label>
                                            <EditorConvertToHTML
                                                text={product?.trainingMaterials[index].description?.zh}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'zh', index, true)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="buttons">
                            <div onClick={() => {
                                this.addKeyFeature()
                            }} className="button blue add clearfix">Add new key feature
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}

export default TrainingMaterials
import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'

class ComplaintView extends React.Component {
    constructor(props) {
        super(props)
        this.apiURL = process.env.REACT_APP_API_URL

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to complaint list', path: '/complaints'},
            imgPath: 'storage/complaint-picture/',
            complaint: null
        }
    }

    componentDidMount() {
        API.getComplaint(this.props.match.params.complaintId).then(complaint => {
            let complaintInfo = {}

            // Complainant information
            complaintInfo.name = complaint.complainantInformation.name
            complaintInfo.phone = complaint.complainantInformation.phone
            complaintInfo.province = complaint.complainantInformation.province
            complaintInfo.city = complaint.complainantInformation.city
            if (complaint.complainantInformation.natureOfAuthentication === 'request-authentication-of-a-suspicious-product') {
                complaintInfo.natureOfAuthentication = 'Request authentication of a suspicious product'
            } else if (complaint.complainantInformation.natureOfAuthentication === 'report-a-suspicious-counterfeiting-activity') {
                complaintInfo.natureOfAuthentication = 'Report a suspicious counterfeiting activity'
            }

            // Complaint environment
            // offline
            complaintInfo.environment = complaint.complaintEnvironment.environment
            complaintInfo.cityEnvironment = complaint.complaintEnvironment.city
            complaintInfo.districtEnvironment = complaint.complaintEnvironment.district
            complaintInfo.detailedAddressEnvironment = complaint.complaintEnvironment.detailedAddress
            // online
            complaintInfo.shopURLEnvironment = complaint.complaintEnvironment.shopURL
            if (complaint.complaintEnvironment.purchaseOrNot === true) {
                complaintInfo.purchaseOrNotEnvironment = 'Yes'
            } else if (complaint.complaintEnvironment.purchaseOrNot === false) {
                complaintInfo.purchaseOrNotEnvironment = 'No'
            }
            complaintInfo.reasonEnvironment = complaint.complaintEnvironment.reason

            // Complaint detail
            complaintInfo.brandInvolved = complaint.complaintDetail.brandInvolved
            complaintInfo.quantity = complaint.complaintDetail.quantity
            complaintInfo.informerComment = complaint.complaintDetail.informerComment

            complaintInfo.pictures = complaint.pictures
            if (complaint.willingToBeContacted === true) {
                complaintInfo.willingToBeContacted = 'Yes'
            } else if (complaint.willingToBeContacted === false) {
                complaintInfo.willingToBeContacted = 'No'
            }
            this.setState({
                loading: false,
                complaint: complaintInfo
            })
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Complaints details'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <div className="block">
                        <div className="fields clearfix">
                            <div className="fields clearfix">
                                <h3>Complainant information</h3>
                                <div className="field" key="name">
                                    <label>Name</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.name}/>
                                </div>
                                <div className="field" key="phone">
                                    <label>Mobile number</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.phone}/>
                                </div>
                                <div className="field" key="province">
                                    <label>Province</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.province}/>
                                </div>
                                <div className="field" key="city">
                                    <label>City of the complainant</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.city}/>
                                </div>
                                <div className="wide-field" key="natureOfAuthentication">
                                    <label>Nature of the authentication</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.natureOfAuthentication}/>
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Complaint environment</h3>
                                <div className="field" key="name">
                                    <label>Offline / Online</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.environment}/>
                                </div>
                                {
                                    this.state.complaint.environment === 'offline' &&
                                    <div>
                                        <div className="field" key="cityEnvironment">
                                            <label>City</label>
                                            <input type="text"
                                                   disabled
                                                   value={this.state.complaint.cityEnvironment}/>
                                        </div>
                                        <div className="field" key="districtEnvironment">
                                            <label>District</label>
                                            <input type="text"
                                                   disabled
                                                   value={this.state.complaint.districtEnvironment}/>
                                        </div>
                                        <div className="field" key="natureOfAuthentication">
                                            <label>Detailed address</label>
                                            <input type="text"
                                                   disabled
                                                   value={this.state.complaint.detailedAddressEnvironment}/>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.complaint.environment === 'online' &&
                                    <div>
                                        <div className="field" key="shopURLEnvironment">
                                            <label>URL page or Wechat Account ID</label>
                                            <input type="text"
                                                   disabled
                                                   value={this.state.complaint.shopURLEnvironment}/>
                                        </div>
                                        <div className="field" key="purchaseOrNotEnvironment">
                                            <label>Did he/she purchase some products</label>
                                            <input type="text"
                                                   disabled
                                                   value={this.state.complaint.purchaseOrNotEnvironment}/>
                                        </div>
                                        <div className="wide-field" key="city">
                                            <label>The reason why he/she believes this merchant is selling suspicious products</label>
                                            {/*<input type="textarea"*/}
                                            {/*       disabled*/}
                                            {/*       value={this.state.complaint.informerComment}/>*/}
                                            <textarea disabled
                                                      value={this.state.complaint.reasonEnvironment}/>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="fields clearfix">
                                <h3>Complaint detail</h3>
                                <div className="field" key="brandInvolved">
                                    <label>Brand involved</label>
                                    <input type="text"
                                           disabled
                                           value={this.state.complaint.brandInvolved}/>
                                </div>
                                <div className="field" key="quantity" style={{display: "block"}}>
                                    <label>Quantity involved</label>
                                    <input type="number"
                                           disabled
                                           value={this.state.complaint.quantity}/>
                                </div>
                                <div className="wide-field" key="empty" style={{paddingTop: "70px"}}></div>
                                <div className="wide-field" key="city">
                                    <label>Informer comment</label>
                                    {/*<input type="textarea"*/}
                                    {/*       disabled*/}
                                    {/*       value={this.state.complaint.informerComment}/>*/}
                                    <textarea disabled
                                              value={this.state.complaint.informerComment}/>
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Pictures</h3>
                                <div className="pictures" key="pictures">
                                    {
                                        Object.keys(this.state.complaint.pictures).map((index) => {
                                            return (
                                                <a href={this.apiURL + this.state.imgPath + this.state.complaint.pictures[index].uri}
                                                   target="_blank">
                                                    <img
                                                        src={this.apiURL + this.state.imgPath + this.state.complaint.pictures[index].uri}
                                                        alt='pics'/>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Willing to be contacted</h3>
                                <p>{this.state.complaint.willingToBeContacted}</p>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
    }
}

export default ComplaintView

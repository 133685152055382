import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'
import moment from "moment"
import {toast} from "react-toastify"

class ReportList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {
                authoritiesName: '',
                status: ''
            },
            sort: ['updatedAt', 'DESC'],
            reports: {
                columns: [
                    {
                        label: 'Report #',
                        key: 'reportId'
                    },
                    {
                        label: 'Authority',
                        key: 'authoritiesName',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['authoritiesName', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'authoritiesName') {
                                this.setState({
                                    sort: ['authoritiesName', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'authoritiesName' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['authoritiesName', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'authoritiesName' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['authoritiesName', 'ASC']
                                })
                            }

                            this.loadEntities(this.state.reports.range)
                        },
                    },
                    {
                        label: 'Creation date',
                        key: 'createdAt',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['createdAt', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'createdAt') {
                                this.setState({
                                    sort: ['createdAt', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['createdAt', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['createdAt', 'ASC']
                                })
                            }

                            this.loadEntities(this.state.reports.range)
                        },
                        view: function (field) {
                            return moment(field).format('YYYY-MM-DD')
                        }
                    },
                    {
                        label: 'Last update',
                        key: 'updatedAt',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['updatedAt', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'updatedAt') {
                                this.setState({
                                    sort: ['updatedAt', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['updatedAt', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['updatedAt', 'ASC']
                                })
                            }

                            this.loadEntities(this.state.reports.range)
                        },
                        view: function (field) {
                            return moment(field).format('YYYY-MM-DD')
                        }
                    },
                    {
                        action: 'select',
                        label: 'Status',
                        props: {
                            options: [
                                {value: 'ongoing', label: 'Ongoing'},
                                {value: 'closed', label: 'Closed'},
                            ],
                        },
                        onChange: (e, entity) => {
                            let nextElement = {}

                            const newEntities = this.state.reports.entities.map(element => {
                                if (element.id !== entity.id) {
                                    return element
                                } else {
                                    nextElement = {...element, status: e.target.value}

                                    return nextElement
                                }
                            })

                            // now need to make the API call
                            this.setState({
                                ...this.state,
                                loading: false,
                                reports: {
                                    ...this.state.reports,
                                    entities: newEntities
                                }
                            }, async () => {
                                try {
                                    await API.updateReportStatus(entity.id, nextElement)
                                    this.setState({loading: false})
                                    toast.success("The report's status has been updated")
                                } catch (err) {
                                    this.setState({loading: false})
                                    console.error(err)
                                    toast.error('An unexpected error has occured, please try again')
                                }
                            })

                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/reports/' + id + '/edit')
                        }
                    }],
                entities: [],
                filters: [
                    {
                        label: 'Authority name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.authoritiesName)
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        authoritiesName: value ? '/' + value + '/i' : ''
                                    },
                                    reports: {
                                        ...this.state.reports,
                                        range: this.state.reports.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.reports.defaultRange)
                                })
                        }, 500)
                    },
                    {
                        label: 'Status',
                        type: 'select',
                        value: null,
                        entities: [
                            {
                                key: '', name: 'All'
                            },
                            {
                                key: 'ongoing', name: 'Ongoing'
                            },
                            {
                                key: 'closed', name: 'Closed'
                            }
                        ],
                        onChange: value => {
                            if (value.key !== this.state.filters.status) {
                                this.setState({
                                    filters: {...this.state.filters, status: value.key ? value.key : ''},
                                    reports: {
                                        ...this.state.reports,
                                        filters: [this.state.reports.filters[0], {
                                            ...this.state.reports.filters[1],
                                            value: value.key
                                        }],
                                        range: this.state.reports.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.reports.defaultRange)
                                })
                            }
                        }
                    }],
                actions: [],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        reports: {
                            ...this.state.reports,
                            range: [0, this.state.reports.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.reports.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/reports/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getReports(this.state.filters, this.state.sort, this.state.reports.defaultRange).then(entities => {
            this.setState({
                ...this.state,
                loading: false,
                reports: {...this.state.reports, entities: entities}
            })

            if (entities.length === this.state.reports.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    reports: {
                        ...this.state.reports,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getReports(this.state.filters, this.state.sort, range).then(entities => {
            this.setState({
                ...this.state,
                loading: false,
                reports: {...this.state.reports, entities: entities}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    reports: {
                        ...this.state.reports,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    reports: {
                        ...this.state.reports,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Reports'}/>
                <Content>
                    <Table settings={this.state.reports}/>
                </Content>
            </>
    }
}

export default ReportList

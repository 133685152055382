import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'

class BrandList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {},
            brands: {
                columns: [
                    {
                        label: 'Brand name in English',
                        key: 'nameEn',
                    },
                    {
                        label: 'Brand name in Chinese',
                        key: 'nameZh',
                    },
                    {
                        label: 'Promoted',
                        key: 'promoted',
                        view: function (field) {
                            if (field) {
                                if (field === true)
                                    return 'Promoted'
                                else
                                    return '-'
                            } else
                                return '-'
                        }
                    },
                    {
                        content: 'See products',
                        action: 'view',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/brands/' + id + '/products')
                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/brands/' + id + '/edit')
                        }
                    }
                ],
                entities: [],
                filters: [
                    {
                        label: 'Brand name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.name && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        name: value ? value : ''
                                    },
                                    brands: {
                                        ...this.state.brands,
                                        range: this.state.brands.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.brands.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.name
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.brands.defaultRange)
                                })
                            }
                        }, 500)
                    }
                ],
                actions: [{
                    classList: 'blue add',
                    label: 'New brand',
                    onClick: () => this.props.history.push('/brands/new')
                }],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        brands: {
                            ...this.state.brands,
                            range: [0, this.state.brands.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.brands.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/brands/' + entity.id + '/products')
                // }
            }
        }
    }

    componentDidMount() {
        API.getBrandsDetails(this.state.filters, this.state.brands.defaultRange).then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })
            this.setState({
                ...this.state,
                loading: false,
                brands: {...this.state.brands, entities: entities}
            })

            if (entities.length === this.state.brands.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    brands: {
                        ...this.state.brands,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getBrandsDetails(this.state.filters, range).then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })

            // let result = []
            // entities.forEach(brand => {
            //     if (brand.nameEn.toLowerCase().indexOf(this.state.filters.name.toLowerCase()) !== -1 || brand.nameZh.indexOf(this.state.filters.name.toLowerCase()) !== -1) {
            //         result.push(brand)
            //     }
            // })

            this.setState({
                ...this.state,
                loading: false,
                brands: {...this.state.brands, entities: entities}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    brands: {
                        ...this.state.brands,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    brands: {
                        ...this.state.brands,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Brands'}/>
                <Content>
                    <Table settings={this.state.brands}/>
                </Content>
            </>
    }
}

export default BrandList

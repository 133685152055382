import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'
import {toast} from "react-toastify"
import moment from "moment";

// All names related to company are "brand" in DB
class UserList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {
                name: '',
                role: '',
                status: ''
            },
            // sort: ['updatedAt', 'DESC'],
            users: {
                columns: [
                    {
                        label: 'User',
                        key: 'name'
                    },
                    {
                        label: 'Company',
                        key: 'companyName'
                    },
                    {
                        label: 'Role',
                        key: 'role',
                        view: function (field) {
                            if (field === 'admin')
                                return 'Admin'
                            else if (field === 'authority')
                                return 'Authority'
                            else
                                return 'Brand'
                        }
                    },
                    {
                        label: 'Status',
                        key: 'status',
                        view: function (field) {
                            if (field === 'validated')
                                return 'Validated'
                            else
                                return 'Waiting for validation'
                        }
                    },
                    {
                        label: 'Application date',
                        key: 'createdAt',
                        // canSort: true,
                        // onSortClick: column => {
                        //     if (this.state.sort === []) {
                        //         this.setState({
                        //             sort: ['createdAt', 'DESC']
                        //         })
                        //     } else if (this.state.sort[0] !== 'createdAt') {
                        //         this.setState({
                        //             sort: ['createdAt', 'ASC']
                        //         })
                        //     } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'ASC') {
                        //         this.setState({
                        //             sort: ['createdAt', 'DESC']
                        //         })
                        //     } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'DESC') {
                        //         this.setState({
                        //             sort: ['createdAt', 'ASC']
                        //         })
                        //     }
                        //
                        //     this.loadEntities(this.state.users.range)
                        // },
                        view: function (field) {
                            return moment(field).format('YYYY-MM-DD')
                        }
                    },
                    {
                        label: 'Validation date',
                        key: 'validationDate',
                        // canSort: true,
                        // onSortClick: column => {
                        //     if (this.state.sort === []) {
                        //         this.setState({
                        //             sort: ['createdAt', 'DESC']
                        //         })
                        //     } else if (this.state.sort[0] !== 'createdAt') {
                        //         this.setState({
                        //             sort: ['createdAt', 'ASC']
                        //         })
                        //     } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'ASC') {
                        //         this.setState({
                        //             sort: ['createdAt', 'DESC']
                        //         })
                        //     } else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'DESC') {
                        //         this.setState({
                        //             sort: ['createdAt', 'ASC']
                        //         })
                        //     }
                        //
                        //     this.loadEntities(this.state.users.range)
                        // },
                        view: function (field) {
                            if (field) {
                                return moment(field).format('YYYY-MM-DD')
                            } else {
                                return ' '
                            }
                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/users/' + id + '/edit')
                        }
                    },
                    {
                        action: 'delete',
                        onClick: (id, key) => {
                            var r = window.confirm("Are you sure you want to delete this user?");
                            if (r === false) return

                            this.setState({loading: true})
                            API.deleteUser(id).then(() => {
                                toast.success('User was successfully deleted')
                                this.loadEntities(this.state.users.range)
                            }).catch(() => {
                                toast.error('An unexpected error has occured, please try again')
                                this.setState({loading: false})
                            })
                        }
                    }
                ],
                entities: [],
                filters: [
                    {
                        label: 'User name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.name)
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        name: value ? '/' + value + '/i' : ''
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                        }, 500)
                    },
                    {
                        label: 'Role',
                        type: 'select',
                        value: null,
                        entities: [
                            {
                                key: '', name: 'All'
                            },
                            {
                                key: 'admin', name: 'Admin'
                            },
                            {
                                key: 'authority', name: 'Authority'
                            },
                            {
                                key: 'brand', name: 'Brand'
                            }
                        ],
                        onChange: value => {
                            if (value.key !== this.state.filters.role) {
                                const newFilters = this.state.users.filters
                                newFilters[1].value = value.key
                                this.setState({
                                    filters: {...this.state.filters, role: value.key ? value.key : ''},
                                    users: {
                                        ...this.state.users,
                                        filters: newFilters,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }
                    },
                    {
                        label: 'Status',
                        type: 'select',
                        value: null,
                        entities: [
                            {
                                key: '', name: 'All'
                            },
                            {
                                key: 'validated', name: 'Validated'
                            },
                            {
                                key: 'waiting-for-validation', name: 'Waiting for validation'
                            }
                        ],
                        onChange: value => {
                            if (value.key !== this.state.filters.status) {
                                const newFilters = this.state.users.filters
                                newFilters[2].value = value.key
                                this.setState({
                                    filters: {...this.state.filters, status: value.key ? value.key : ''},
                                    users: {
                                        ...this.state.users,
                                        filters: newFilters,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }

                        }
                    },
                    {
                        label: 'Company name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.company && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        company: value ? value : ''
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.company
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }, 500)
                    },
                    {
                        label: 'Application date Start',
                        type: 'date',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.applicationStart && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        applicationStart: value,
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.applicationStart
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }, 500)
                    },
                    {
                        label: 'Application date End',
                        type: 'date',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.applicationEnd && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        applicationEnd: value
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.applicationEnd
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }, 500)
                    },
                    {
                        label: 'Validation date Start',
                        type: 'date',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.validationStart && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        validationStart: value,
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.validationStart
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }, 500)
                    },
                    {
                        label: 'Validation date End',
                        type: 'date',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.validationEnd && value.length !== 0) {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        validationEnd: value
                                    },
                                    users: {
                                        ...this.state.users,
                                        range: this.state.users.defaultRange,
                                        loadButton: true
                                    }
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            } else if (value.length === 0) {
                                const newFilters = this.state.filters
                                delete newFilters.validationEnd
                                this.setState({
                                    filters: newFilters
                                }, () => {
                                    this.loadEntities(this.state.users.defaultRange)
                                })
                            }
                        }, 500)
                    }
                ],
                actions: [{
                    classList: 'blue add',
                    label: 'New user',
                    onClick: () => this.props.history.push('/users/new')
                }],
                selectedItems: [],
                multiSelect: false,
                defaultRange: [0, 19],
                range: [0, 19],
                loadButton: false,
                loadMore: () => {
                    this.setState({
                        ...this.state,
                        users: {
                            ...this.state.users,
                            range: [0, this.state.users.range[1] + 20]
                        }
                    })
                    this.loadEntities(this.state.users.range)
                }
                // onRowClick: (entity) => {
                //     this.props.history.push('/users/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getUsersDetails(this.state.filters, this.state.users.defaultRange).then(entities => {
            entities.forEach(entity => {
                if (entity.company) {
                    if (entity.company.name.en) {
                        entity.companyName = entity.company.name.en
                    } else {
                        entity.companyName = entity.company.name.zh
                    }
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })

            if (entities.length === this.state.users.defaultRange[1] + 1) {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: true
                    }
                })
            }
        })
    }

    loadEntities(range) {
        API.getUsersDetails(this.state.filters, range).then(entities => {
            entities.forEach(entity => {
                if (entity.company) {
                    if (entity.company.name.en) {
                        entity.companyName = entity.company.name.en
                    } else {
                        entity.companyName = entity.company.name.zh
                    }
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })

            if (entities.length === range[1] + 1) {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: true
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    users: {
                        ...this.state.users,
                        loadButton: false
                    }
                })
            }
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Users'}/>
                <Content>
                    <Table settings={this.state.users}/>
                </Content>
            </>
    }
}

export default UserList
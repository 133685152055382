import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class CompanyEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Companies', path: '/companies'},
            fields: [
                {
                    label: 'Informations',
                    fields: {
                        nameEn: {
                            label: 'Company name in English',
                            type: 'text'
                        },
                        nameZh: {
                            label: 'Company name in Chinese',
                            type: 'text'
                        },
                    }
                }
            ],
            company: null
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        API.getCompany(this.props.match.params.companyId).then(company => {
            company.nameEn = company.name.en
            company.nameZh = company.name.zh
            this.setState({loading: false, company: company})
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (!fields.nameEn && !fields.nameZh) {
            toast.error('Company name can not be empty')
            this.setState({loading: false})
        } else {
            fields.name.en = fields.nameEn
            fields.name.zh = fields.nameZh
            delete fields.nameEn
            delete fields.nameZh

            API.update('companies', this.props.match.params.companyId, fields).then(() => {
                toast.success('Company has been updated')
                window.location.reload(true)
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({loading: false})
            })
        }
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this company?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteCompany(this.props.match.params.companyId).then(() => {
            toast.success('Company was successfully deleted')
            this.props.history.push('/companies')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit Company'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <Form fields={this.state.fields}
                          entity={this.state.company}
                          cancel={() => this.props.history.push('/companies')}
                          save={this.saveEntity}
                          deleteEntity={this.deleteEntity}/>
                </Content>
            </>
    }
}

export default CompanyEdit